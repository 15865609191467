//
// Swiper
//

.swiper-slide {
	opacity: 1;
	@include transition(opacity .3s);

	.card {
		margin: 0;
	}
}

.swiper-fade {
	.swiper-slide-prev,
	.swiper-slide-next {
		opacity: 0;

	}
}


// Vertical

.swiper-container-vertical {
	width: 100%;
	height: 100%;

	.swiper-slide {
	    text-align: center;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
}


// Pagination

.swiper-pagination {
	left: 0;
}

.swiper-pagination-bullet {
	width: .4rem;
	height: .4rem;
	margin-right: .45rem;
	opacity: .5;
	background: $pagination-bullet-bg;
	outline: none;
	@if $enable-transitions {
		transition: $transition-base;
	}

	&:hover {
		opacity: 1;
	}
}

.swiper-pagination-bullet-active {
	transform: scale(1.4);
	background: $pagination-bullet-active-bg;
	opacity: 1;
}

// Next/Previous controls

.swiper-button,
.swiper-button {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin-top: 0;
	transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    background-image: none;
	outline: none;
    @include transition($transition-base);
	@include box-shadow($box-shadow-sm);

	&:after {
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: $icon-font-family;
		font-weight: 700;
	}
}

.swiper-button-sm {
	width: 34px;
    height: 34px;
    line-height: 34px;
}

.swiper-button-next {
	right: -44px;

	&.swiper-button-sm {
		right: -14px;
	}

	&:after {
		content: "\f105";
	}

	@include media-breakpoint-down(sm) {
		right: -20px;
	}
}

.swiper-button-prev {
	left: 0;

	&:after {
		content: "\f104";
	}

	@include media-breakpoint-down(sm) {
		left: 24px;
	}
}

.swiper-button-disabled {
	opacity: 0 !important;
}
