//
// Custom rating
//

.custom-rating {
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    min-height: $font-size-base * $line-height-base;

    input {
        position: absolute;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
        opacity: 0;
    }

    label {
        width: $custom-rating-indicator-size;
        height: $custom-rating-indicator-size;
        margin-right: .375rem;
        white-space: nowrap;
        position: relative;
        margin-bottom: 0;
        vertical-align: middle;
        cursor: pointer;
        // color: $star-rating-color;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: $custom-rating-indicator-size;
            height: $custom-rating-indicator-size;
            background-color: $custom-rating-indicator-bg;
            color: $custom-rating-indicator-color;
            border-radius: $custom-rating-indicator-border-radius;
            content: "\f005";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            @include transition($transition-base);
        }
    }

    > input:checked ~ label {
        &:before {
            background-color: $custom-rating-indicator-checked-bg;
            color: $custom-rating-indicator-checked-color;
        }

    }

    > label:hover,
    > label:hover ~ label {
        &:before {
            background-color: $custom-rating-indicator-checked-bg;
            color: $custom-rating-indicator-checked-color;
        }
    }

    > input:checked + label:hover,
    > input:checked + label:hover ~ label,
    > input:checked ~ label:hover,
    > input:checked ~ label:hover ~ label,
    > label:hover ~ input:checked ~ label {
        &:before {
            background-color: transparentize($custom-rating-indicator-checked-bg, .4);
            color: transparentize($custom-rating-indicator-checked-color, .4);
        }
    }
}
