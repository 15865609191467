//
// List
//


.list-icon {
	> i,
	> svg {
	    margin-right: 1rem;
	    font-size: $font-size-sm;
	    position: relative
	}
}

.list-bullet {
	> i,
	> svg {
	    margin-right: 1rem;
	    font-size: .875rem;
	    position: relative
	}
}

.list-unstyled {
	.list-link {
		display: block;
		color: $body-color;
		padding-top: .25rem;
		padding-bottom: .25rem;

		&:hover {
			color: theme-color("primary");
		}

		> i {
			margin-right: .5rem;
			font-size: .75rem;
		}
	}
}

//
.list-inline-item {
	vertical-align: middle;
}
