//
// Vertical navbar + Sidenav
//


.navbar-vertical {

    .navbar-nav {
        .nav-link {

            padding: $navbar-vertical-nav-link-padding-y $navbar-vertical-nav-link-padding-x;
            font-size: $navbar-nav-link-font-size;
            font-weight: $navbar-nav-link-font-weight;
            display: flex;
            align-items: center;
            white-space: nowrap;

            &.active {
                position: relative;

            }


            // Icon
            > i,
            > svg {
                min-width: $navbar-icon-min-width;
                margin-right: 0;
                font-size: .9375rem;
                line-height: ($font-size-base * $line-height-base);
            }


            // Dropdown

            .dropdown-menu {
                border: none;

                .dropdown-menu {
                    margin-left: $dropdown-item-padding-x / 2;
                }
            }

            &[data-toggle="collapse"] {
                &:after {
                    display: inline-block;
                    font-style: normal;
                    font-size: $font-size-sm;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: $icon-font-family;
                    font-weight: 700;
                    content: "\f105";
                    margin-left: auto;
                    @include transition($transition-base);
                }

                // Expanded
                &[aria-expanded="true"] {
                    &:after {
                        color: theme-color("primary");
                        transform: rotate(90deg);
                    }
                }
            }
        }

        & > .nav-item {
            margin-top: 2px;

            > .nav-link {
                &.active {
                    background: $gray-100;
                    margin-right: .5rem;
                    margin-left: .5rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-radius: $border-radius;
                }
            }
        }

        .nav-sm {
            .nav-link {
                font-size: $font-size-sm;
            }
        }

        // Second level
        .nav .nav-link {
            padding-top: $navbar-vertical-nav-link-padding-y / 1.5;
            padding-bottom: $navbar-vertical-nav-link-padding-y / 1.5;
            padding-left: $navbar-padding-x + $navbar-icon-min-width - 1rem;
        }


        // Third level
        .nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x * 1.5 + $navbar-icon-min-width - 1rem;
        }
    }

    // Navbar light

    &.navbar-light {
        background-color: $navbar-light-bg;
        border-color: $navbar-light-border-color;

        .navbar-nav .nav-link {
            &[data-toggle="collapse"] {
                &:after {
                    color: $navbar-light-color;
                }
            }
        }
    }


    // Navbar dark

    &.navbar-dark {
        background-color: $navbar-dark-bg;
        border-color: $navbar-dark-border-color;

        .navbar-nav .nav-link {
            &[data-toggle="collapse"] {
                &:after {
                    color: $navbar-dark-color;
                }
            }
        }
    }

    // Expanded navbar specific styles

    &.navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                @include media-breakpoint-down($breakpoint) {
                    // background: $white !important;

                    .navbar-collapse:before {
                        content: '';
                        display: block;
                        border-top-width: 1px;
                        border-top-style: solid;
                        margin: .75rem -1rem;
                    }

                    .collapse {
                        position: relative;

                        &.show {
                            .nav {
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: $navbar-icon-min-width - 1rem;
                                    top: 0;
                                    height: 100%;
                                    border-left: 1px solid;
                                }
                            }
                        }
                    }

                    &.navbar-light {
                        .navbar-collapse::before {
                            border-top-color: rgba(0, 0, 0, .2);
                        }

                        .collapse .nav {
                            &:before {
                                border-color: rgba(0, 0, 0, .2);
                            }
                        }
                    }

                    &.navbar-dark {
                        .navbar-collapse::before {
                            border-top-color: rgba(255, 255, 255, .2);
                        }

                        .collapse .nav {
                            &:before {
                                border-color: rgba(255, 255, 255, .2);
                            }
                        }
                    }
                }

                @include media-breakpoint-up($next) {
                    display: block;
                    width: 100%;
                    max-width: $navbar-vertical-open-width;
                    overflow-y: auto;
                    z-index: 1000;
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 1rem;
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    @include border-radius($navbar-vertical-border-radius);
                    @include transition($transition-base);

                    @if $enable-shadows {
                        @include box-shadow($navbar-vertical-box-shadow);
                    }

                    @include media-breakpoint-up(xs) {
                        + .main-content {
                            margin-left: $navbar-vertical-width;
                        }
                    }

                    .nav-link-text,
                    .navbar-heading {
                        opacity: 0;
                        pointer-events: none;
                        @include transition($transition-base);
                    }

                    &.show {
                        max-width: $navbar-vertical-open-width;
                        @include border-radius($navbar-vertical-open-border-radius);

                        .nav-link-text,
                        .navbar-heading {
                            opacity: 1;
                            pointer-events: auto;
                        }

                        + .main-content {
                            margin-left: $navbar-vertical-open-width;
                            // transform: translateX($navbar-vertical-open-width);
                        }
                    }

                    &.hide {
                        max-width: $navbar-vertical-width;

                        .nav-link-text {
                            opacity: 0;
                            pointer-events: none;
                        }

                        + .main-content {
                            margin-left: $navbar-vertical-width;
                        }
                    }

                    // Navbar brand
                    .navbar-brand {
                        margin-right: 0;
                        padding-top: 1.25rem;
                        padding-bottom: 1.25rem;
                    }

                    .navbar-inner {
                        padding-left: $navbar-vertical-padding-x;
                        padding-right: $navbar-vertical-padding-x;
                    }

                    &.fixed-left,
                    &.fixed-right {
                        position: fixed;
                        top: 0;
                        bottom: 0;
                    }

                    // Container
                    >[class*="container"] {
                        flex-direction: column;
                        align-items: stretch;
                        min-height: 100%;
                        padding-left: 0;
                        padding-right: 0; // Target IE 10 & 11
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            min-height: none;
                            height: 100%;
                        }
                    }


                    // Fixes the vertical navbar to the left
                    &.fixed-left {
                        left: 0;
                        border-width: 0 $navbar-vertical-border-width 0 0;
                    }


                    // Fixed the vertical navbar to the right
                    &.fixed-right {
                        right: 0;
                        border-width: 0 0 0 $navbar-vertical-border-width;
                    }


                    // Navbar collapse
                    .navbar-collapse {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;

                        opacity: 1;

                        > * {
                            min-width: 100%;
                        }
                    }


                    // Navbar navigation

                    .navbar-nav {
                        flex-direction: column;
                        margin-left: -$navbar-vertical-padding-x;
                        margin-right: -$navbar-vertical-padding-x;
                    }


                    // Second level
                    .nav .nav-link {
                        padding-left: $navbar-vertical-padding-x + $navbar-icon-min-width;
                    }


                    // Third level
                    .nav .nav .nav-link {
                        padding-left: $navbar-vertical-padding-x * 1.5 + $navbar-icon-min-width;
                    }

                }
            }
        }
    }
}
